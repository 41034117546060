import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, Flex, Text, Image } from "@chakra-ui/react";
import { Modal } from "@/components/Modal";
import { useBridgeSocket } from "@/contexts/BridgeSocket";
import { useAuthToken } from "@/hooks/useAuthToken";
import { GearList } from "@/components/gear/GearList";
import { RadioButton } from "@/components/input/RadioButton";
import { useBridgeGearInput } from "@/hooks/useBridgeGearInput";
import { useWithdraw } from "@/hooks/useWithdraw";
import { useDeposit } from "@/hooks/useDeposit";
import { useGearSubgraph } from "@/hooks/useGearSubgraph";
import { useSwitchNetwork } from "wagmi";
import { getGearDefinition } from "../../gear";
import { useAccount, useNetwork } from "wagmi";
import { NETWORK } from "@/env";
import { FragmentTab } from "@/components/modals/Fragments";
export var BridgeGearModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, openAuthModal = _a.openAuthModal, onRefresh = _a.onRefresh;
    var _b = useState("withdraw"), tab = _b[0], setTab = _b[1];
    var token = useAuthToken()[0];
    function getNetworkId() {
        if (NETWORK === "mainnet") {
            return 42161; // Arbitrum
        }
        else if (NETWORK === "testnet") {
            return 421613; // Arbitrum Goerli
        }
        else {
            throw new Error("Unsupported network: ".concat(NETWORK));
        }
    }
    var chain = useNetwork().chain;
    var networkId = getNetworkId();
    return (React.createElement(Modal, { isOpen: isOpen, onClose: onClose, contentProps: {
            maxWidth: "960px",
            padding: 0,
        } },
        React.createElement(Flex, { align: "center", justify: "space-between", flexShrink: 0, padding: "20px 30px", borderBottomWidth: 1, borderColor: "blackAlpha.600", gap: 2, wrap: "wrap" },
            React.createElement(Text, { fontSize: "2xl", fontWeight: "bold", color: "whiteAlpha.700" }, "Gear Bridge"),
            React.createElement(RadioButton, { value: tab, onSelect: setTab, options: [
                    { value: "withdraw", label: "Withdraw" },
                    { value: "deposit", label: "Deposit" },
                    { value: "fragments", label: "Fragments" },
                ] })),
        React.createElement(Button, { style: {
                width: "100%",
                display: "flex",
                backgroundColor: "rgba(0, 0, 0, 0.10)",
            }, color: "white", _hover: { bg: "green.400", transition: "background-color 0.2s" }, onClick: onRefresh }, "Refresh"),
        tab === "withdraw" && (React.createElement(Withdraw, { openAuthModal: openAuthModal, isOpen: isOpen, onClose: onClose, onRefresh: onRefresh, chain: chain, networkId: networkId })),
        tab === "deposit" && (React.createElement(Deposit, { isOpen: isOpen, onClose: onClose, onRefresh: onRefresh, chain: chain, networkId: networkId })),
        tab === "fragments" && token && (React.createElement(FragmentTab, { jwtToken: token, onClose: onClose }))));
};
var Withdraw = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, openAuthModal = _a.openAuthModal, onRefresh = _a.onRefresh, chain = _a.chain, networkId = _a.networkId;
    var token = useAuthToken()[0];
    var _b = useBridgeSocket(), isAuthenticated = _b.isAuthenticated, login = _b.login, fetchItems = _b.fetchItems, gameGear = _b.items, totalGearCount = _b.unfilteredItemCount;
    var _c = useBridgeGearInput(gameGear), gearInput = _c.input, selectGear = _c.select;
    var _d = useWithdraw(), socketWithdraw = _d.withdraw, isSuccess = _d.isSuccess;
    var withdrawls = useBridgeSocket().withdrawls;
    var _e = useSwitchNetwork({
        throwForSwitchChainNotSupported: true,
    }), chains = _e.chains, error = _e.error, isLoading = _e.isLoading, pendingChainId = _e.pendingChainId, switchNetwork = _e.switchNetwork;
    var aggregatedWithdrawals = useMemo(function () {
        return withdrawls === null || withdrawls === void 0 ? void 0 : withdrawls.reduce(function (acc, _a) {
            var _b = _a.withdrawl, id = _b.id, amount = _b.amount;
            if (acc[id]) {
                acc[id] += amount;
            }
            else {
                acc[id] = amount;
            }
            return acc;
        }, {});
    }, [withdrawls]);
    var totalSelected = useMemo(function () {
        var total = 0;
        Object.values(gearInput).forEach(function (amount) { return (total += amount); });
        return total;
    }, [gearInput]);
    var totalAvailable = useMemo(function () {
        var total = 0;
        gameGear === null || gameGear === void 0 ? void 0 : gameGear.forEach(function (_a) {
            var amount = _a.amount;
            return (total += amount);
        });
        return total;
    }, [gameGear]);
    var totalPendingItems = useMemo(function () {
        var total = 0;
        withdrawls === null || withdrawls === void 0 ? void 0 : withdrawls.forEach(function (_a) {
            var amount = _a.withdrawl.amount;
            return (total += amount);
        });
        return total;
    }, [withdrawls]);
    useEffect(function () {
        if (isSuccess) {
            onClose();
        }
    }, [isSuccess]);
    // Authenticate
    useEffect(function () {
        if (isOpen && !isAuthenticated && token) {
            login(token);
        }
    }, [isOpen, token, isAuthenticated]);
    // Fetch gear
    useEffect(function () {
        if (isOpen && isAuthenticated) {
            fetchItems();
            console.log("fetched items");
        }
    }, [isOpen, gameGear, isAuthenticated]);
    var withdraw = function () {
        var itemAmounts = [];
        Object.keys(gearInput).forEach(function (idString) {
            var id = Number(idString);
            itemAmounts.push({ id: id, amount: gearInput[id] });
        });
        socketWithdraw(itemAmounts);
    };
    var handleSwitchNetwork = function () {
        console.log("Attempting to switch network...");
        if (switchNetwork) {
            console.log("Switch network function is available. Switching network...");
            console.log("Network ID:", networkId);
            switchNetwork(networkId);
        }
        else {
            console.error("switchNetwork function is undefined");
        }
    };
    console.log("isAuthenticated", isAuthenticated);
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { flexGrow: 1, overflowY: "auto", maxH: "calc(100vh - 200px)", p: "10px" }, !token ? (React.createElement(Flex, { flexDir: "column", align: "center" },
            React.createElement(Text, { color: "gray.500" }, "You must be signed in to bridge gear"),
            React.createElement(Button, { onClick: openAuthModal, mt: 3 }, "Login"))) : !isAuthenticated ? (React.createElement(Flex, { flexDir: "column", align: "center" },
            React.createElement(Text, { color: "gray.500" }, "Connect to the bridging server"),
            React.createElement(Button, { onClick: function () { return login(token); }, mt: 3 }, "Connect"))) : (gameGear === null || gameGear === void 0 ? void 0 : gameGear.length) === 0 ? (React.createElement(Flex, { p: 10, gap: 4, direction: "column", align: "center" },
            React.createElement(Text, { align: "center", color: "gray.400" }, "You don't have any withdrawable in game gear."),
            totalGearCount > 0 && (React.createElement(Text, { align: "center", color: "gray.600" },
                "You have ",
                totalGearCount,
                " piece",
                totalGearCount === 1 ? "" : "s",
                " ",
                "of unbridgeable (Common/Uncommon) gear.")))) : gameGear ? (React.createElement(GearList, { gear: gameGear, gearInput: gearInput, selectGear: selectGear })) : (React.createElement(Text, { color: "gray.500", align: "center" }, "Loading..."))),
        React.createElement(Footer, null,
            React.createElement(Text, { color: "whiteAlpha.600", fontWeight: "semibold" },
                totalSelected,
                " / ",
                totalAvailable),
            React.createElement(Text, { color: "whiteAlpha.600", fontWeight: "semibold" }, totalPendingItems > 0
                ? "Withdraw to complete ".concat(totalPendingItems, " pending items")
                : "No pending withdrawals"),
            React.createElement(Flex, { align: "center", p: 2 },
                React.createElement("div", { style: {
                        display: "flex",
                        overflowX: "auto",
                        maxWidth: "calc(75px * 3)",
                    } }, Object.entries(aggregatedWithdrawals || {}).map(function (_a) {
                    var idString = _a[0], amount = _a[1];
                    var id = Number(idString);
                    var image = getGearDefinition(id).image;
                    return (React.createElement(Flex, { align: "center", key: id, mr: 4 },
                        React.createElement(Image, { src: image, boxSize: "25px", m: 2 }),
                        React.createElement(Text, { mr: 2 },
                            "x",
                            Number(amount))));
                })),
                React.createElement(Button, { bg: "green.500", color: "black", _hover: { bg: "green.400", transition: "background-color 0.2s" }, onClick: chain && chain.id === networkId ? withdraw : handleSwitchNetwork }, chain && chain.id === networkId ? "Withdraw" : "Switch Networks")))));
};
var Deposit = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, onRefresh = _a.onRefresh, chain = _a.chain, networkId = _a.networkId;
    var address = useAccount().address;
    var chainGear = useGearSubgraph(address).data;
    var _b = useSwitchNetwork({
        throwForSwitchChainNotSupported: true,
    }), chains = _b.chains, error = _b.error, isLoading = _b.isLoading, pendingChainId = _b.pendingChainId, switchNetwork = _b.switchNetwork;
    var chainGearQuantities = (chainGear === null || chainGear === void 0 ? void 0 : chainGear.map(function (_a) {
        var tokenId = _a.tokenId, amount = _a.amount;
        return ({
            id: tokenId,
            amount: amount,
        });
    }).filter(function (_a) {
        var amount = _a.amount;
        return amount > 0;
    })) || [];
    var _c = useBridgeGearInput(chainGearQuantities), gearInput = _c.input, selectGear = _c.select;
    var depositItemsInput = useMemo(function () {
        return Object.entries(gearInput).map(function (_a) {
            var id = _a[0], amount = _a[1];
            return ({
                id: id,
                amount: amount,
            });
        });
    }, [gearInput]);
    var _d = useDeposit(depositItemsInput), write = _d.write, isSuccess = _d.isSuccess;
    var totalSelected = useMemo(function () {
        var total = 0;
        Object.values(gearInput).forEach(function (amount) { return (total += amount); });
        return total;
    }, [gearInput]);
    var totalAvailable = useMemo(function () {
        var total = 0;
        chainGear === null || chainGear === void 0 ? void 0 : chainGear.forEach(function (_a) {
            var amount = _a.amount;
            return (total += amount);
        });
        return total;
    }, [chainGear]);
    useEffect(function () {
        if (isSuccess) {
            onClose();
        }
    }, [isSuccess]);
    var handleSwitchNetwork = function () {
        console.log("Attempting to switch network...");
        if (switchNetwork) {
            console.log("Switch network function is available. Switching network...");
            console.log("Network ID:", networkId);
            switchNetwork(networkId);
        }
        else {
            console.error("switchNetwork function is undefined");
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { flexGrow: 1, overflowY: "auto", maxH: "calc(100vh - 200px)", p: "10px" }, !chainGear ? (React.createElement(Text, { p: 10, align: "center", color: "gray.600" }, "Loading...")) : chainGearQuantities.length === 0 ? (React.createElement(Flex, { p: 10, gap: 4, direction: "column", align: "center" },
            React.createElement(Text, { align: "center", color: "gray.400" }, "You don't have any depositable on chain gear."))) : (React.createElement(GearList, { gear: chainGearQuantities, gearInput: gearInput, selectGear: selectGear }))),
        React.createElement(Footer, null,
            React.createElement(Text, { color: "green.600" },
                totalSelected,
                " / ",
                totalAvailable),
            React.createElement(Button, { bg: "green.500", color: "black", onClick: chain && chain.id === networkId ? write : handleSwitchNetwork, _hover: { bg: "green.400", transition: "background-color 0.2s" } }, chain && chain.id === networkId ? "Deposit" : "Switch Networks"))));
};
var Footer = function (_a) {
    var children = _a.children;
    return (React.createElement(Flex, { align: "center", justify: "space-between", flexShrink: 0, padding: "20px 30px", borderTopWidth: 1, borderColor: "blackAlpha.600" }, children));
};
