var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { createContext, useContext, useEffect, useState, useReducer, } from "react";
import socketIOClient from "socket.io-client";
import { BRIDGE_SERVER_URL } from "../env";
import { getTokenPayload } from "@/auth/token";
export var WithdrawStatus;
(function (WithdrawStatus) {
    WithdrawStatus["IDLE"] = "idle";
    WithdrawStatus["CLICKED"] = "clicked";
    WithdrawStatus["RECEIVED"] = "received";
})(WithdrawStatus || (WithdrawStatus = {}));
var initialState = {
    withdrawStatus: WithdrawStatus.IDLE,
};
// Emit rate limits (milliseconds)
var LOGIN_RATE_LIMIT = 2000;
var ITEMS_RATE_LIMIT = 5000;
var BridgeSocketContext = createContext(null);
function reducer(state, action) {
    switch (action.type) {
        case "SET_WITHDRAW_STATUS":
            return __assign(__assign({}, state), { withdrawStatus: action.payload });
        default:
            throw new Error();
    }
}
export var BridgeSocketProvider = function (_a) {
    var children = _a.children;
    var _b = useState(null), socket = _b[0], setSocket = _b[1];
    var _c = useState(null), items = _c[0], setItems = _c[1];
    var _d = useState(null), token = _d[0], setToken = _d[1];
    var _e = useState(0), lastLoginCall = _e[0], setLastLoginCall = _e[1];
    var _f = useState(0), lastItemsCall = _f[0], setLastItemsCall = _f[1];
    var _g = useState(0), unfilteredItemCount = _g[0], setUnfilteredItemCount = _g[1];
    var _h = useState([]), withdrawls = _h[0], setWithdrawls = _h[1];
    var _j = useReducer(reducer, initialState), state = _j[0], dispatch = _j[1];
    useEffect(function () {
        var newSocket = socketIOClient(BRIDGE_SERVER_URL);
        setSocket(newSocket);
        return function () {
            newSocket.close();
        };
    }, []);
    useEffect(function () {
        if (!socket)
            return;
        socket.on("login", handleLoginResponse);
        socket.on("items", handleItemsResponse);
        return function () {
            socket.off("login", handleLoginResponse);
            socket.off("items", handleItemsResponse);
        };
    }, [socket]);
    var login = function (token) {
        var now = Date.now();
        if (!socket || now - lastLoginCall < LOGIN_RATE_LIMIT)
            return;
        setLastLoginCall(now);
        var address = getTokenPayload(token).sub;
        socket.emit("login", address, token);
    };
    var fetchItems = function () {
        var now = Date.now();
        if (!socket || now - lastItemsCall < ITEMS_RATE_LIMIT)
            return;
        setLastItemsCall(now);
        socket.emit("items", token);
        socket.emit("getwithdrawls", token);
    };
    var withdraw = function (itemAmounts) {
        if (!socket)
            return;
        var ids = [];
        var amounts = [];
        itemAmounts.forEach(function (_a) {
            var id = _a.id, amount = _a.amount;
            ids.push(id);
            amounts.push(amount);
        });
        socket.emit("withdraw", token, ids, amounts);
        dispatch({ type: "SET_WITHDRAW_STATUS", payload: WithdrawStatus.CLICKED });
    };
    // Temporary method of getting test items
    var hax = function () {
        if (!socket)
            return;
        socket.emit("hax", token);
    };
    var handleLoginResponse = function (response) {
        if (response.success) {
            setToken(response.token);
        }
        else {
            console.error(response.message);
        }
    };
    var handleItemsResponse = function (items) {
        var itemQuantities = [];
        setUnfilteredItemCount(items.length);
        items.forEach(function (_a) {
            var gearId = _a.gearId, rarity = _a.rarity;
            if (rarity !== "Common" && rarity !== "Uncommon") {
                var itemQuantity = itemQuantities.find(function (_a) {
                    var id = _a.id;
                    return id === gearId;
                });
                if (itemQuantity) {
                    itemQuantity.amount++;
                }
                else {
                    itemQuantities.push({ id: gearId, amount: 1 });
                }
            }
        });
        setItems(itemQuantities);
    };
    var isAuthenticated = token !== null;
    var bridgeSocket = {
        socket: socket,
        isAuthenticated: isAuthenticated,
        login: login,
        fetchItems: fetchItems,
        hax: hax,
        withdraw: withdraw,
        items: items,
        unfilteredItemCount: unfilteredItemCount,
        withdrawls: withdrawls,
        setWithdrawls: setWithdrawls,
        withdrawStatus: state.withdrawStatus,
        setWithdrawStatus: function (status) {
            return dispatch({ type: "SET_WITHDRAW_STATUS", payload: status });
        },
    };
    return (React.createElement(BridgeSocketContext.Provider, { value: bridgeSocket }, children));
};
export var useBridgeSocket = function () {
    var context = useContext(BridgeSocketContext);
    if (!context) {
        throw new Error("useBridgeSocket must be used within a BridgeSocketProvider");
    }
    return context;
};
