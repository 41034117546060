import gearData from "./data/gearData.json";
export var gearDefinitions = gearData.map(function (data) {
    var rarity = "Uncommon";
    var gearType = "Weapons";
    data.attributes.forEach(function (attribute) {
        if (attribute.trait_type === "Rarity") {
            rarity = attribute.value;
        }
        else if (attribute.trait_type === "Type") {
            gearType = attribute.value;
        }
    });
    var image = data.image.replace("ipfs://", "https://ipfs.io/ipfs/");
    return {
        id: data.edition,
        name: data.name,
        image: image,
        rarity: rarity,
        gearType: gearType,
    };
});
var gearDefinitionFallback = {
    id: -1,
    name: "Unknown",
    image: "",
    rarity: "Common",
    gearType: "Padding",
};
export var getGearDefinition = function (id) {
    return gearDefinitions.find(function (gearDef) { return gearDef.id === id; }) ||
        gearDefinitionFallback;
};
