import React from "react";
import { useAuthStatus } from "@/hooks/useAuthStatus";
import { darkTheme, RainbowKitAuthenticationProvider, RainbowKitProvider, } from "@rainbow-me/rainbowkit";
import { authenticationAdapter } from "@/auth/authenticationAdapter";
import { chains } from "@/walletConfig";
export var WrappedRainbowKitProvider = function (_a) {
    var children = _a.children;
    var authStatus = useAuthStatus();
    return (React.createElement(RainbowKitAuthenticationProvider, { adapter: authenticationAdapter, status: authStatus },
        React.createElement(RainbowKitProvider, { chains: chains, theme: darkTheme({ overlayBlur: "small" }), coolMode: true }, children)));
};
