import { getAuthToken, getAuthTokenAddress } from "@/auth/token";
import { dispatchModalEvent } from "../events/modalEvents";
import { setAuthToken } from "@/auth/token";
window.koteWeb = {
    isAuthenticated: function () { return !!getAuthToken(); },
    getAuthToken: function () { return getAuthToken(); },
    removeAuthToken: function () { return setAuthToken(null); },
    getUserAddress: function () { return getAuthTokenAddress(); },
    openAuthModal: function () {
        dispatchModalEvent({ modal: "AuthModal", isOpen: true });
    },
    openBridgeGearModal: function () {
        dispatchModalEvent({ modal: "BridgeGearModal", isOpen: true });
    },
    getLoginData: function () {
        var data = { wallet: window.koteWeb.getUserAddress(), token: window.koteWeb.getAuthToken() };
        return JSON.stringify(data);
    }
};
