import React, { useState, useEffect, useMemo } from "react";
import { Box, Button, Flex, Image, Text, Grid, GridItem, } from "@chakra-ui/react";
import { useFragments } from "@/hooks/useFragments";
import fragmentData from "@/data/fragmentData.json";
import { UserRejectedRequestError } from "wagmi";
import { useSwitchNetwork, useNetwork } from "wagmi";
import { NETWORK } from "@/env";
var AWS_URL = "https://assets.knightsoftheether.com";
export var FragmentTab = function (_a) {
    var jwtToken = _a.jwtToken, onClose = _a.onClose;
    var _b = useState([]), selectedFragments = _b[0], setSelectedFragments = _b[1];
    var _c = useState(false), isContractError = _c[0], setIsContractError = _c[1];
    var _d = useState(0), refresh = _d[0], setRefresh = _d[1];
    var _e = useState(false), isClaiming = _e[0], setIsClaiming = _e[1];
    var chain = useNetwork().chain;
    var _f = useSwitchNetwork({
        throwForSwitchChainNotSupported: true,
    }), chains = _f.chains, switchNetworkError = _f.error, switchNetworkIsLoading = _f.isLoading, pendingChainId = _f.pendingChainId, switchNetwork = _f.switchNetwork;
    var networkId = useMemo(function () {
        if (NETWORK === "mainnet") {
            return 42161; // Arbitrum
        }
        else if (NETWORK === "testnet") {
            return 421613; // Arbitrum Goerli
        }
        else {
            throw new Error("Unsupported network: ".concat(NETWORK));
        }
    }, []);
    var handleSwitchNetwork = function () {
        console.log("Attempting to switch network...");
        if (switchNetwork) {
            console.log("Switch network function is available. Switching network...");
            console.log("Network ID:", networkId);
            switchNetwork(networkId);
        }
        else {
            console.error("switchNetwork function is undefined");
        }
    };
    var _g = useFragments(jwtToken, selectedFragments, refresh), fragments = _g.fragments, isLoading = _g.isLoading, isSuccess = _g.isSuccess, fragmentsError = _g.error, isError = _g.isError, writeAsync = _g.writeAsync, isWriteLoading = _g.isWriteLoading, handleClaimButtonClick = _g.handleClaimButtonClick, state = _g.state, dispatch = _g.dispatch;
    var handleClaim = function (fragment) {
        console.log("Claiming fragment:", fragment);
        if (fragment.realAmount < 1) {
            return;
        }
        setSelectedFragments([fragment]);
        handleClaimButtonClick();
    };
    var handleClaimAll = function () {
        console.log("Claiming all fragments");
        var availableFragments = fragments.filter(function (fragment) { return fragment.realAmount >= 1; });
        setSelectedFragments(availableFragments);
        handleClaimButtonClick();
    };
    var totalAvailableForClaim = useMemo(function () {
        return fragments.reduce(function (total, fragment) { return total + fragment.realAmount; }, 0);
    }, [fragments]);
    useEffect(function () {
        if (!isWriteLoading) {
            setIsContractError(false);
        }
    }, [isWriteLoading]);
    useEffect(function () {
        if (fragmentsError) {
            if (fragmentsError instanceof UserRejectedRequestError) {
                // User rejected the transaction, reset the state
                setRefresh(function (refresh) { return refresh + 1; });
            }
            else {
                setIsContractError(true);
            }
        }
    }, [fragmentsError]);
    useEffect(function () {
        if (isSuccess) {
            console.log("Claim successful");
            onClose();
        }
    }, [isSuccess, onClose]);
    return (React.createElement(Box, { flexGrow: 1, overflowY: "auto", maxH: "calc(100vh - 200px)", m: 3 },
        React.createElement(Grid, { templateColumns: "repeat(5, 1fr)", gap: 3 }, fragments.map(function (fragment, index) {
            var gridColumn = (index % 5) + 1;
            var gridRow = Math.floor(index / 5) + 1;
            return (React.createElement(GridItem, { key: index, gridColumn: gridColumn, gridRow: gridRow },
                React.createElement(FragmentListing, { fragment: fragment, handleClaim: handleClaim, isWriteLoading: isWriteLoading || (chain === null || chain === void 0 ? void 0 : chain.id) !== networkId, isContractError: isContractError, chain: chain, networkId: networkId })));
        })),
        React.createElement(Flex, { flexDirection: "column", alignItems: "center", marginTop: 4 },
            React.createElement(Text, { fontSize: "xl", fontWeight: "bold" }, chain && chain.id === networkId ? "Claim All" : "Switch Network"),
            React.createElement(Button, { bg: "green.500", color: "black", _hover: { bg: "green.400", transition: "background-color 0.2s" }, onClick: chain && chain.id === networkId
                    ? handleClaimAll
                    : handleSwitchNetwork, marginTop: 2 }, chain && chain.id === networkId ? "Claim All" : "Switch Networks"),
            React.createElement(Text, { fontSize: "md", marginTop: 2 },
                "Total Available for Claim: ",
                totalAvailableForClaim))));
};
var FragmentListing = function (_a) {
    var fragment = _a.fragment, handleClaim = _a.handleClaim, isWriteLoading = _a.isWriteLoading, isContractError = _a.isContractError, chain = _a.chain, networkId = _a.networkId;
    var id = fragment.id, realAmount = fragment.realAmount;
    var image = "".concat(AWS_URL, "/fragments/").concat(id, ".jpg");
    var getFragmentNameById = function (id) {
        var fragment = fragmentData.find(function (fragment) { return fragment.id === id; });
        return fragment ? fragment.name : "Unknown";
    };
    return (React.createElement(Flex, { w: "170px", p: 1, flexDir: "column", align: "center", textAlign: "center", bg: "blackAlpha.400", borderWidth: 1, borderColor: "gray.800", transition: "border 0.2s", rounded: "lg" },
        React.createElement(Box, { borderColor: "black", borderWidth: 2, transition: "all 0.2s", bg: "blackAlpha.600", minH: 10, overflow: "hidden" },
            React.createElement(Image, { src: image, w: "full" })),
        React.createElement(Box, null,
            React.createElement(Text, { color: "whiteAlpha.600", fontSize: "sm" }, getFragmentNameById(id)),
            React.createElement(Text, { color: "whiteAlpha.400", fontSize: "xs" },
                "Available to Claim: ",
                realAmount)),
        React.createElement(Button, { bg: "green.500", color: "black", _hover: { bg: "green.400", transition: "background-color 0.2s" }, onClick: function () { return handleClaim(fragment); }, isDisabled: realAmount < 1 ||
                isWriteLoading ||
                isContractError ||
                (chain === null || chain === void 0 ? void 0 : chain.id) !== networkId }, "Claim")));
};
