var _a;
import { ALCHEMY_KEY, NETWORK } from "@/env";
import { connectorsForWallets, } from "@rainbow-me/rainbowkit";
import { metaMaskWallet } from "@rainbow-me/rainbowkit/wallets";
import { configureChains, createClient } from "wagmi";
import { arbitrum, arbitrumGoerli } from "wagmi/chains";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { providers } from "ethers";
export var isMainnet = NETWORK === "mainnet";
var providerWrapper = function () {
    var provider = alchemyProvider({ apiKey: ALCHEMY_KEY });
    return function (chain) {
        var p = provider(chain);
        if (p) {
            p.provider = function () {
                var _a, _b;
                return new providers.AlchemyProvider({
                    chainId: chain.id,
                    name: chain.network,
                    ensAddress: (_b = (_a = chain.contracts) === null || _a === void 0 ? void 0 : _a.ensRegistry) === null || _b === void 0 ? void 0 : _b.address,
                }, ALCHEMY_KEY);
            };
        }
        return p;
    };
};
console.log("RANDOM MESSAGE TO PROVE CHANGE");
console.log("Provider Wrapper:", providerWrapper);
export var chains = (_a = configureChains([isMainnet ? arbitrum : arbitrumGoerli], [providerWrapper()]), _a.chains), provider = _a.provider, webSocketProvider = _a.webSocketProvider;
console.log("Chains:", chains);
console.log("Provider:", provider);
console.log("WebSocket Provider:", webSocketProvider);
var connectorsFunction = connectorsForWallets([
    {
        groupName: "Recommended",
        wallets: [metaMaskWallet({ chains: chains })],
    },
]);
console.log("Connectors Function:", connectorsFunction);
var connectors = connectorsFunction();
console.log("Connectors:", connectors);
export var wagmiClient = createClient({
    autoConnect: true,
    provider: provider,
    connectors: connectors,
    webSocketProvider: webSocketProvider,
});
console.log("Wagmi Client:", wagmiClient);
var mainnetContracts = {
    bridge: "0x20349E4E216b35f7863ACB0eD474CD68F9da655a",
    gear: "0xdA52BB340e8F7b582970523C03451E4dDe5C6Bcd",
    fragments: "0x57811CCA25CfC9c176a5515f23ded33a4eAe380D",
};
var testnetContracts = {
    bridge: "0x129695b1Ad0e1c2e9BBdC683E090948f54D38183",
    gear: "0x71Ade25F547a45f0494E0edaE98721c7AF758f2d",
    fragments: "0xBe5bca7A065789aCabC829932D51fb67bbE195a0",
};
export var contracts = isMainnet ? mainnetContracts : testnetContracts;
