import React, { useState, useEffect, useCallback } from "react";
import { AuthModal } from "./components/modals/AuthModal";
import { BridgeGearModal } from "./components/modals/BridgeGearModal";
import { TreasureAuthModal } from "./components/modals/TreasureAuthModal";
import { useModalContext } from "./contexts/ModalContext";
import { Unity, useUnityContext } from "react-unity-webgl";
import { useAuthStatus } from './hooks/useAuthStatus';
import { FixedContainer, UnityGameWrapper } from './components/unity';
import IntroVideo from './components/video/IntroVideo';
import './index.css';
import './style.css';
import { HOST } from "@/env";
var App = function () {
    var _a = useModalContext(), isAuthModalOpen = _a.isAuthModalOpen, isBridgeGearModalOpen = _a.isBridgeGearModalOpen, isTreasureAuthModalOpen = _a.isTreasureAuthModalOpen, openAuthModal = _a.openAuthModal, closeAuthModal = _a.closeAuthModal, closeBridgeGearModal = _a.closeBridgeGearModal, closeTreasureAuthModal = _a.closeTreasureAuthModal;
    var _b = useState(false), isPlaymodeReady = _b[0], setPlaymodeReady = _b[1];
    var _c = useState(false), isIntroPlaying = _c[0], setIntroPlaying = _c[1];
    var authStatus = useAuthStatus();
    var isLoggedIn = authStatus === "authenticated";
    var _d = useUnityContext({
        loaderUrl: HOST + "/Build/WebGL.loader.js",
        dataUrl: HOST + "/Build/WebGL.data",
        frameworkUrl: HOST + "/Build/WebGL.framework.js",
        codeUrl: HOST + "/Build/WebGL.wasm",
        streamingAssetsUrl: HOST + "/StreamingAssets"
    }), unityProvider = _d.unityProvider, loadingProgression = _d.loadingProgression, isLoaded = _d.isLoaded, sendMessage = _d.sendMessage, addEventListener = _d.addEventListener;
    var onUnityEvent = useCallback(function (data) {
        var json;
        try {
            json = JSON.parse(data);
            if (json.eventName == "logout") {
                window.koteWeb.removeAuthToken();
                window.location.reload();
            }
            if (json.eventName == "open-bridge") {
                window.koteWeb.openBridgeGearModal();
            }
            if (json.eventName == 'initiate') {
                console.log("Handle initiate here");
            }
            else {
                console.log("Unknown message eventName " + json.eventName);
                console.log("1");
            }
        }
        catch (exception) {
            console.error(exception);
        }
        var unityMessage = json.eventName || undefined; // Assign an empty string if value is undefined
        if (unityMessage == undefined) {
            console.error("This UnityEvent shouldn't be undefined, contact support");
        }
        return unityMessage;
    }, []);
    useEffect(function () {
        addEventListener("GetUnityMessage", onUnityEvent);
        return function () {
            removeEventListener("GetUnityMessage", onUnityEvent);
        };
    }, [addEventListener, removeEventListener, onUnityEvent]);
    var onPressLogin = function () {
        console.log("Check this out connor!");
        window.koteWeb.openAuthModal();
    };
    var onPressPlay = function () {
        setIntroPlaying(true);
        console.log("press play");
    };
    //moved onPlay functionlity here to ensure video plays first
    var handleVideoEnd = function () {
        setIntroPlaying(false);
        setPlaymodeReady(true);
        var token = window.koteWeb.getAuthToken() || 'undefined'; // Assign an empty string if value is undefined
        if (token == 'undefined') {
            console.error("This token shouldn't be undefined, contact support");
        }
        sendMessage("LoadingManager", "Login", token);
    };
    var onRefresh = function () {
        console.log("[Web] Called refresh-armory from javascript to unity");
        sendMessage("WebBridge", "OnWebMessage", "refresh-armory");
    };
    var isPlayButtonDisabled = !isLoaded && isLoggedIn;
    var isLoginButtonDisabled = !isLoaded;
    var isModalBlocking = isAuthModalOpen || isBridgeGearModalOpen;
    var renderUnityGame = function () { return (React.createElement(UnityGameWrapper, null,
        React.createElement("div", { style: { display: isIntroPlaying ? 'none' : 'block' } },
            React.createElement(Unity, { className: "unity-game", unityProvider: unityProvider })),
        isIntroPlaying && React.createElement(IntroVideo, { onEnd: handleVideoEnd }))); };
    var renderAuthModal = function () { return (React.createElement(AuthModal, { isOpen: isAuthModalOpen, onClose: closeAuthModal })); };
    var renderBridgeGearModal = function () { return (React.createElement(BridgeGearModal, { isOpen: isBridgeGearModalOpen, onClose: closeBridgeGearModal, openAuthModal: openAuthModal, onRefresh: onRefresh })); };
    var renderTreasureAuthModal = function () { return (React.createElement(TreasureAuthModal, { isOpen: isTreasureAuthModalOpen, onClose: closeTreasureAuthModal })); };
    var renderBackgroundImage = function () { return (React.createElement("div", { className: "background-image" })); };
    var renderPlayButton = function () { return (React.createElement("div", { className: "custom-button-container ".concat(isPlayButtonDisabled ? 'disabled' : ''), onClick: function () { return !isPlayButtonDisabled && onPressPlay(); } },
        React.createElement("div", { className: "custom-button ".concat(isPlayButtonDisabled ? 'disabled' : '') },
            React.createElement("h1", null, "PLAY")),
        isPlayButtonDisabled && React.createElement("div", { className: "disabled-overlay" }))); };
    var renderLoginButton = function () { return (React.createElement("div", { className: "custom-button-container ".concat(isLoginButtonDisabled ? 'disabled' : ''), onClick: function () { return !isLoginButtonDisabled && onPressLogin(); } },
        React.createElement("div", { className: "custom-button ".concat(isLoginButtonDisabled ? 'disabled' : '') },
            React.createElement("h1", null, "LOGIN")),
        isLoginButtonDisabled && React.createElement("div", { className: "disabled-overlay" }))); };
    var renderButtonsContainer = function () { return (React.createElement("div", { className: "buttons-container", style: { display: isIntroPlaying ? 'none' : '' } },
        isLoggedIn && renderPlayButton(),
        renderLoginButton())); };
    var renderLoadingText = function () { return (React.createElement("h1", { className: "loading-application-text" },
        "Loading Unity... ",
        Math.round(loadingProgression * 100),
        "%")); };
    var handleFullscreen = function () {
        var element = document.documentElement;
        if (document.fullscreenElement !== null) {
            // Website is in fullscreen
            document.exitFullscreen();
        }
        else {
            if (element.requestFullscreen) {
                element.requestFullscreen();
            }
        }
    };
    return (React.createElement(FixedContainer, null,
        renderUnityGame(),
        renderAuthModal(),
        renderBridgeGearModal(),
        renderTreasureAuthModal(),
        !isPlaymodeReady && renderBackgroundImage(),
        !isPlaymodeReady && !isModalBlocking && renderButtonsContainer(),
        !isLoaded && renderLoadingText(),
        React.createElement("div", { id: 'spacing', className: 'spacing' }),
        React.createElement("div", { className: "unity-fullscreen-button", id: "unity-fullscreen-button", onClick: handleFullscreen })));
};
export default App;
