var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useEffect, useMemo } from "react";
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction, } from "wagmi";
import { contracts } from "@/walletConfig";
import BridgeAbi from "../abis/bridge.json";
export var useDeposit = function (depositItems) {
    var _a;
    var args = useMemo(function () {
        var ids = [];
        var amounts = [];
        depositItems.forEach(function (_a) {
            var id = _a.id, amount = _a.amount;
            ids.push(id);
            amounts.push(amount);
        });
        return [ids, amounts];
    }, [depositItems]);
    var _b = usePrepareContractWrite({
        address: contracts.bridge,
        abi: BridgeAbi,
        functionName: "depositItems",
        args: args,
        enabled: depositItems.length > 0,
    }), config = _b.config, prepError = _b.error;
    var contract = useContractWrite(config);
    var transaction = useWaitForTransaction({ hash: (_a = contract.data) === null || _a === void 0 ? void 0 : _a.hash });
    var error = transaction.error || contract.error || prepError;
    useEffect(function () {
        if (error) {
            console.error(error);
        }
    }, [error]);
    return __assign(__assign({}, contract), { isLoading: transaction.isLoading || contract.isLoading, isSuccess: transaction.isSuccess, error: error, isError: !!error });
};
