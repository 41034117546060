var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useEffect, useMemo, useState } from "react";
import { useBridgeSocket } from "../contexts/BridgeSocket";
import { useBridgeSocketListener } from "./useBridgeSocketListener";
import BridgeAbi from "../abis/bridge.json";
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction, } from "wagmi";
import { contracts } from "@/walletConfig";
import { WithdrawStatus } from "../contexts/BridgeSocket";
import { useModalContext } from "@/contexts/ModalContext";
export var useWithdraw = function () {
    var _a = useWithdrawSocket(), withdraw = _a.withdraw, withdrawls = _a.withdrawls, socketError = _a.error, withdrawStatus = _a.withdrawStatus, setWithdrawStatus = _a.setWithdrawStatus;
    var _b = useWithdrawContract(withdrawls), write = _b.write, args = _b.args, data = _b.data, isSuccess = _b.isSuccess, isLoading = _b.isLoading, contractError = _b.error;
    useEffect(function () {
        console.log("withdrawStatus changed:", withdrawStatus);
    }, [withdrawStatus]);
    console.log("write:", write);
    console.log("contractError:", contractError);
    var error = contractError || socketError;
    var isReady = !!write && args[0].length > 0;
    useEffect(function () {
        if (withdrawStatus === WithdrawStatus.RECEIVED && isReady) {
            write();
            setWithdrawStatus(WithdrawStatus.IDLE);
        }
    }, [isReady, withdrawStatus, write, withdrawls]);
    useEffect(function () {
        if (error) {
            // Reset state here if error with contract or write
            setWithdrawStatus(WithdrawStatus.IDLE);
            // Other state resets if necessary
        }
    }, [error]);
    return {
        data: data,
        withdraw: withdraw,
        write: isReady ? write : undefined,
        isReady: isReady,
        isSuccess: isSuccess,
        isLoading: isLoading,
        isError: !!error,
        error: error,
        withdrawls: withdrawls,
    };
};
var useWithdrawSocket = function () {
    var _a = useState(false), isSuccess = _a[0], setIsSuccess = _a[1];
    var _b = useState(null), error = _b[0], setError = _b[1];
    var _c = useBridgeSocket(), withdraw = _c.withdraw, fetchItems = _c.fetchItems, withdrawls = _c.withdrawls, setWithdrawls = _c.setWithdrawls, withdrawStatus = _c.withdrawStatus, setWithdrawStatus = _c.setWithdrawStatus;
    var isBridgeGearModalOpen = useModalContext().isBridgeGearModalOpen;
    useBridgeSocketListener("withdrawls", function (payload) {
        console.log("withdrawls event received");
        if (payload.success === false) {
            setError(new Error("".concat(payload.message)));
        }
        else {
            setWithdrawls(payload.withdrawls);
            setIsSuccess(true);
            setError(null);
            if (withdrawStatus === WithdrawStatus.CLICKED) {
                setWithdrawStatus(WithdrawStatus.RECEIVED);
            }
        }
    });
    useEffect(function () {
        if (!isBridgeGearModalOpen) {
            setIsSuccess(false);
            setWithdrawStatus(WithdrawStatus.IDLE); //make sure if modal closes, status reset to IDLE
        }
    }, [isBridgeGearModalOpen]);
    useEffect(function () {
        if (isSuccess) {
            fetchItems();
        }
    }, [isSuccess]);
    useEffect(function () {
        if (error) {
            console.error("Withdraw Error:");
            console.error(error);
        }
    }, [error]);
    return {
        withdrawls: withdrawls,
        withdraw: withdraw,
        isSuccess: isSuccess,
        isError: !!error,
        error: error,
        withdrawStatus: withdrawStatus,
        setWithdrawStatus: setWithdrawStatus,
    };
};
var useWithdrawContract = function (withdrawls) {
    var _a;
    var args = useMemo(function () {
        var ids = [];
        var amounts = [];
        var nonces = [];
        var signatures = [];
        withdrawls === null || withdrawls === void 0 ? void 0 : withdrawls.forEach(function (_a) {
            var signature = _a.sig, _b = _a.withdrawl, id = _b.id, amount = _b.amount, nonce = _b.nonce;
            if (amount > 0) {
                ids.push(id);
                amounts.push(amount);
                nonces.push(nonce);
                signatures.push(signature);
            }
        });
        return [ids, amounts, nonces, signatures];
    }, [withdrawls]);
    var _b = usePrepareContractWrite({
        address: contracts.bridge,
        abi: BridgeAbi,
        functionName: "withdrawItems",
        args: args,
    }), config = _b.config, prepError = _b.error;
    useEffect(function () {
        if (prepError) {
            console.error(prepError);
        }
    }, [prepError]);
    console.log("config:", config); // Check if config is correctly formed
    var contract = useContractWrite(config);
    console.log("contract:", contract);
    var transaction = useWaitForTransaction({ hash: (_a = contract.data) === null || _a === void 0 ? void 0 : _a.hash });
    var transactionStatus = transaction.status;
    var error = transaction.error || contract.error || prepError;
    useEffect(function () {
        if (error) {
            console.error(error);
        }
    }, [error]);
    return __assign(__assign({}, contract), { args: args, isLoading: transaction.isLoading || contract.isLoading, isSuccess: transaction.isSuccess, error: error, isError: !!error });
};
